@import "../../assets/sass/variables";

.addClient {
  width: 100%;
  min-width: 947px;
  max-width: 947px;

  &-title {
    padding: 15px 30px;
    border-bottom: 1px solid $borderColor;
    border-radius: 3px 3px 0px 0px;
    background: $btnGradient;

    p {
      margin: 10px 0 0;
    }
    h1 {
      color: $white;
      font-size: 14px;
    }
  }

  &-content {
    padding: 30px;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    &-aside {
      width: 25%;
      padding: 30px 10px 30px 30px;
      background: $tabBgColor;
      flex: 1;
      .source-list {
        max-height: calc(100vh - 240px);
        margin-bottom: 0px;
      }
    }
    &.medical-entities {
      min-height: 500px;
    }
    .license-key {
      display: grid;
      grid-template-columns: 140px 1fr;
      align-items: center;
      column-gap: 20px;
    }
  }
}

.edit-profile {
  .ant-modal-body {
    .addClient-title {
      padding: 15px;
      border-bottom: none;
    }
  }

  .ant-radio-group {
    margin-top: 10px;
  }

  .ant-radio-wrapper {
    color: #fff;
    margin: 0 20px 0 0;

    .ant-radio-checked .ant-radio-inner {
      border-color: $primaryColor;
    }

    .ant-radio-inner:after {
      background: $primaryColor;
    }
  }
}
// *--------select dropdown----------*
.new-role {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background: transparent;
    border: none;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 10px 6px;
    height: 44px;
    border-radius: 3px;
    border: 1px solid #c4c4c4;
    margin-top: 5px;
    box-shadow: 0px 0px 26px rgb(0 0 0 / 5%);
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: $baseColor;
  }

  .ant-select-arrow .anticon > svg {
    color: $tabdetail;
  }

  .ant-select-arrow .anticon {
    margin-top: 6px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 1.5715;
  }
}

.deleteConfirmation {
  padding: 80px 70px;
  border: 0px;

  h1 {
    font-family: Font-Semibold;
  }

  p {
    padding-bottom: 30px;
    margin-bottom: 0;
  }
}

.patient-project {
  text-align: center;

  .info {
    color: $textPrimaryColor;
    font-size: 16px;
  }
  ul {
    margin-bottom: 0px;
    max-height: 250px;
    overflow-y: auto;

    .ant-checkbox-wrapper {
      width: 100%;
      color: $textPrimaryColor;
      padding: 10px 0;
      border-bottom: 1px solid $borderColor;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primaryColor;
    border-color: $primaryColor;
  }
  .ant-checkbox-checked:after {
    border-color: $primaryColor;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: $primaryColor;
  }
  .aiButton {
    margin: 40px 0 30px 0;
  }
}

.first-project {
  &-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
}

.ant-modal-close {
  display: flex;
  width: min-content !important;
  height: min-content !important;
  padding: 0 !important;
  margin: 6px !important;
}

.range-picker {
  height: 40px;
}

.tag-container {
  max-width: 100%; 
  width: 100%; 
}